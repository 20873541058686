import React from 'react'
import { Box, Grid, Typography } from "@mui/material";
import Styles from "./auth_layout.module.scss";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AccountSetup from "../../app/Layout/Settings/components/Profile/components/AccountSetup/AccountSetup";
import { useEffect, useState } from "react";
import { dispatchStore } from "../../../Store/store";
import { entityActions } from "../../../_actions";
import { getUser } from "../../../_helpers";
import { useSelector } from "react-redux";
import { selectAllData } from "../../../_reducers/entity.reducer";
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {AathAppLoadingScreen} from '../../app/Layout/AppLoadingScreen';

const Auth_Layout = ({ children, setp, updateStep, setSetpNumberAuthapp, environmentLoader }: any) => {
  const [setpNumberAuth, setSetpNumberAuth] = useState<any>(0)
  const [stepData, setStepData] = useState<any>("")
  const [renderGet, setRenderGet] = useState<any>(false)
  const [completed, setCompleted] = useState<any>()
  const [stepObj, setStepObj] = useState<any>();
  const [setsetp, setSetstep] = useState(0)
  console.log(setpNumberAuth, "setpNumberAuth");

  const navigate = useNavigate();

  const data = useSelector((state) => selectAllData(state));
  const theme = createTheme({
    palette: {
      primary: {
        main: "#000",
      },
    },
  });
  console.log(stepObj, "stepObj");

  const listofsetup = [
    { name: "Business type", id: 1, pathname: "/v2/account-setup" },
    { name: "Business details", id: 2, pathname: "/v2/document-upload" },
    { name: "Ownership details", id: 3, pathname: "/v2/document-upload?step-3" },
    { name: "Expected activity", id: 4, pathname: "/v2/entity" },
    { name: "Select currency", id: 5, pathname: "/v2/currency" },
    { name: "Review", id: 6, pathname: "/v2/reviews" },
    { name: "Verification", id: 7, pathname: "/v2/verify-identity" },
    { name: "", id: 8, pathname: "/v2/account-setup-completed" },
  ];
  const Array_Route = [
    { id: 1, name: 'step_1', pathname: '/v2/account-setup' },
    { id: 2, name: 'step_2', pathname: '/v2/document-upload' },
    { id: 3, name: 'step_3', pathname: '/v2/document-upload?step-3' },
    { id: 4, name: 'step_4', pathname: '/v2/entity' },
    { id: 5, name: 'step_5', pathname: '/v2/currency' },
    { id: 6, name: 'step_6', pathname: '/v2/reviews' },
    { id: 7, name: 'step_7', pathname: '/v2/verify-identity' },
    { id: 8, name: 'step_8', pathname: '/v2/account-setup-completed' },
  ];

  // useEffect(() => {
  //   if (!!user) {
  //     getStep();
  //   }
  // }, [setpNumberAuth]);
  // const getStep = () => {
  //   dispatchStore(entityActions.getCurrentStepAction());
  //   setRenderGet(true);
  // };

  useEffect(() => {
    if (user?.data?.entity?.status !== "VERIFIED") {
      if (stepObj) {
        const steps = Object.keys(stepObj).filter(key => key.startsWith('step_'));
        const firstFalseStep = steps.find(step => !stepObj[step]);
        const firstFalseStepTrue = steps.find(step => stepObj[step]);
        console.log(firstFalseStepTrue, steps, firstFalseStep, stepObj, "firstFalseStepTrue");

        if (firstFalseStep) {
          const route = Array_Route.find(route => route.name === firstFalseStep);
          console.log(window?.location?.pathname + window?.location?.search, route, Array_Route, firstFalseStep, steps, "routeroute");

          if (route) {
            console.log(window?.location?.pathname + window?.location?.search == route.pathname, window?.location?.pathname + window?.location?.search, route.pathname, "sggszdzsdgsdzgg");

            if (window?.location?.pathname + window?.location?.search == route.pathname) {
              // if (route.pathname.includes('step-3') || route.name == 'step_3') {
              navigate(route.pathname)
              // }
            } else {
              // if (route.pathname.includes('step-3') || route.name == 'step_3') {

              // } else {
              navigate(route.pathname);

              // }
            }
          } else {
            console.log("All steps are true or no false steps found.");

          }
          // setSetpNumberAuth(route)
        }
      }
    }
  }, [stepObj, window, window?.location?.pathname, setpNumberAuth, setsetp])
  const user = getUser()?.data ?? '';
  const getStep = () => {
    dispatchStore(entityActions.getCurrentStepAction(setRenderGet));

    console.log('dslkjvljfdlkjvfdl')
  };
  console.log(renderGet, stepData, 'dkkdospkpokok')
  useEffect(() => {
    if (!!user) {
      getStep();
    }
  }, [setpNumberAuth, window.location, window, window.location.pathname]);
  React.useEffect(() => {
    if (data?.GET_CURRENT_STEP_SUCCESS !== undefined && renderGet) {
      setRenderGet(false);
      const dataList = data?.GET_CURRENT_STEP_SUCCESS?.data;
      setStepObj(dataList);
      setStepData(dataList);
      const trueKeys = Object.entries(dataList)
        .filter(([key, value]) => value === true)
        .map(([key]) => key);
      console.log(trueKeys, "trueKeys");

      setCompleted(trueKeys)
    }
  }, [data?.GET_CURRENT_STEP_SUCCESS, renderGet]);
  // React.useEffect(() => {
  //   if (data?.GET_CURRENT_STEP_SUCCESS !== undefined && renderGet) {
  //     setRenderGet(false);
  //     const dataList = data?.GET_CURRENT_STEP_SUCCESS?.data;
  //     setStepData(dataList);
  //     const trueKeys = Object.entries(dataList)
  //       .filter(([key, value]) => value === true)
  //       .map(([key]) => key);
  //       console.log(trueKeys,"trueKeys");

  //     setCompleted(trueKeys)
  //   }
  // }, [data?.GET_CURRENT_STEP_SUCCESS, renderGet]);

  useEffect(() => {
    const FindData = listofsetup.find((data: any) => (data.pathname == window.location.pathname + window.location.search))
    console.log(FindData, listofsetup, "FindData");

    setSetpNumberAuth(FindData?.id)
  }, [window.location.pathname, setp])
  console.log(window.location.pathname + window.location.search, listofsetup, "sdfszfsfsdf");

  var Withe = setpNumberAuth * 14.28
  const themeline = createTheme(
    {
      palette: {
        primary: { main: '#000' },
        mode: "light",
      },
    }
  )
  console.log((setpNumberAuth == 2 && !!user?.entity?.id), "(setpNumberAuth == 2 && !!user?.entity?.id)");

  return (
    <>
      <ThemeProvider theme={theme}>
        {environmentLoader ? (
          <ThemeProvider theme={themeline}>
            <AathAppLoadingScreen />
          </ThemeProvider>
        ) : (
          <Grid container>
            <Grid item md={12} className={Styles.grid_header}>
              <Box>
                <img src="/logoAuth.svg" />
              </Box>
            </Grid>
            <Box sx={{ width: `${Withe}%` }} className={Styles.BoxButtom}></Box>
            <Grid item md={12} className={Styles.gridLayout}>
              <Grid item md={2}>
                {setpNumberAuth == 1 || setpNumberAuth == 7 || setpNumberAuth == 8 || (setpNumberAuth == 2 && !!user?.entity?.id) ?
                  "" : <Button
                    id="button_back"
                    style={{
                      color: '#025141',
                      marginLeft: '17px',
                      marginBottom: '20px',
                    }}
                    onClick={() => {

                      updateStep(setpNumberAuth - 1, false, '');
                      setSetpNumberAuthapp(setpNumberAuth - 1)
                      setSetstep(setpNumberAuth - 1)
                      // navigate(backpath);
                      // if (backpath == '/v2/account-setup') {
                      //   setSetpNumberAuth(1);
                      //   updateStep(1,false,navigate(backpath));
                      //   localStorage.setItem('registerStep', '1');
                      // }
                    }}
                    className="payment-journey-container__detailbox__white-btn"
                  >
                    <ThemeProvider theme={theme}>
                      <ArrowBackIcon
                        style={{ fontSize: '19px', marginRight: '10px' }}
                      />
                    </ThemeProvider>
                    Back
                  </Button>}
                <Box className={Styles.classNameBox}>
                  <Box className={Styles.box_conteansetp}>
                    <Typography>{`Step ${setpNumberAuth == 8 ? 7 : !!setpNumberAuth ? setpNumberAuth : "7"} > 7`}</Typography>
                  </Box>
                  <Box className={Styles.maindev}>
                    {listofsetup.map((element: any, index: any) => {
                      const nameStep = `step_${index + 1}`
                      const selected_ = completed?.includes(nameStep)
                      const selected = (element.pathname == window.location.pathname + window.location.search) || selected_
                      console.log(selected, nameStep, selected_, completed, element.pathname, window.location.pathname + window.location.search, "selectedselected123");

                      return (
                        <>
                          {element.id == 8 ? "" :
                            <Box
                              className={
                                selected
                                  ? Styles.activeContean
                                  : Styles.box_conteanList
                              }
                            >
                              {selected ? <Chakbox /> : <UnChakbox />}
                              <Typography>{element.name}</Typography>
                            </Box>}
                        </>
                      )
                    })}
                  </Box>
                </Box>

              </Grid>
              <Grid item md={9.5} >
                {children}
                {/* {children} */}
              </Grid>
              <Grid item md={0.5}></Grid>
            </Grid>
          </Grid>
        )}
      </ThemeProvider>

    </>
  );
};
export default Auth_Layout;
const UnChakbox = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.6875C4.95 1.6875 1.6875 4.95 1.6875 9C1.6875 13.05 4.95 16.3125 9 16.3125C13.05 16.3125 16.3125 13.05 16.3125 9C16.3125 8.2125 16.2067 7.42838 15.9255 6.69712L15.012 7.59375C15.1245 8.04375 15.1881 8.49375 15.1881 9C15.1881 12.4312 12.4318 15.1875 9.00056 15.1875C5.56931 15.1875 2.8125 12.4312 2.8125 9C2.8125 5.56875 5.56875 2.8125 9 2.8125C10.6875 2.8125 12.2029 3.48412 13.2716 4.55288L14.0625 3.762C12.7688 2.46825 10.9688 1.6875 9 1.6875ZM15.345 4.095L9 10.4417L6.5925 8.03419L5.7825 8.8425L8.595 11.655L9 12.0409L9.405 11.6544L16.155 4.90444L15.345 4.09444V4.095Z"
      fill="#DDDDDD"
    />
  </svg>
);
const Chakbox = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.6875C4.95 1.6875 1.6875 4.95 1.6875 9C1.6875 13.05 4.95 16.3125 9 16.3125C13.05 16.3125 16.3125 13.05 16.3125 9C16.3125 8.2125 16.2067 7.42838 15.9255 6.69712L15.012 7.59375C15.1245 8.04375 15.1881 8.49375 15.1881 9C15.1881 12.4312 12.4318 15.1875 9.00056 15.1875C5.56931 15.1875 2.8125 12.4312 2.8125 9C2.8125 5.56875 5.56875 2.8125 9 2.8125C10.6875 2.8125 12.2029 3.48412 13.2716 4.55288L14.0625 3.762C12.7688 2.46825 10.9688 1.6875 9 1.6875ZM15.345 4.095L9 10.4417L6.5925 8.03419L5.7825 8.8425L8.595 11.655L9 12.0409L9.405 11.6544L16.155 4.90444L15.345 4.09444V4.095Z"
      fill="#025041"
    />
  </svg>
);

import { authHeader, handleResponse, authHeaderproduction } from '../_helpers';
import { getEnvironment } from '../_helpers/environmentProperties';

const baseURL =
  getEnvironment() === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_STAGING_API_URL;

export const entityService = {
  getEntityTypes,
  getEntityActivities,
  createEntity,
  updateEntity,
  getEntities,
  getBilldata,
  getBusinessDetails,
  getCurrentStep,
  setCurrentStep,
  getDetailsForNewApi,
  saveBusinessDetailsApi,
  updetDataAction,
  SaveEntity,
  createEntityv2
};
function SaveEntity(EntityRequest, api) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(EntityRequest)
  }
  // console.log('Service: ', requestOptions, token);
  return fetch(`${baseURL}${api}`, requestOptions).then(handleResponse)
}
function updetDataAction(req) {
  console.log(req, 'reqreq')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req)
  }

  return fetch(`${baseURL}/v2/update-entity-after-review`, requestOptions).then(handleResponse)
}
function saveBusinessDetailsApi(req, payload) {
  if (payload == true) {
    req.selfie = ''
    req.kra = ''
    req.idFront = ''
    req.idBack = ''
    req.businessCertificate = ''
    req.passport = ''
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req)
  }
  return fetch(`${baseURL}/choice/save-business-details`, requestOptions).then(handleResponse)
}
// get entity types api
function getDetailsForNewApi(id) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },

  }
  //   https://dev.users.niobi.co/api/choice/get-business-details/4f426fa2-a5ae-4c6a-bfe6-c6d3edb249c6
  return fetch(`${baseURL}/choice/get-business-details/${id}`, requestOptions).then(handleResponse)
}
function setCurrentStep(req) {
  console.log(req, 'reqreq')
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(req)
  }

  return fetch(`${baseURL}/v2/update-step`, requestOptions).then(handleResponse)
}
function getCurrentStep() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },

  }

  return fetch(`${baseURL}/v2/check-step`, requestOptions).then(handleResponse)
}
function getEntityTypes() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-entity-types`, requestOptions).then(
    handleResponse
  );
}

// get entity activities api
function getEntityActivities() {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-entity-activities`, requestOptions).then(
    handleResponse
  );
}

// create entity api
function createEntity(EntityRequest, APIURL, path) {
  var requestOptions = {}
  console.log(EntityRequest, APIURL, path, requestOptions, !path, "EntityRequest123");
  if (APIURL == "https://users.niobi.co/api") {
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeaderproduction() },
      body: JSON.stringify(EntityRequest),
    };

  } else {
    requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(EntityRequest),
    };
  }
  console.log('Service: ', requestOptions);
  return fetch(`${APIURL}/create-entity`, requestOptions).then(handleResponse);
}
function createEntityv2(EntityRequest, api) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(EntityRequest)
  }
  // console.log('Service: ', requestOptions, token);
  return fetch(`${baseURL}${api}`, requestOptions).then(handleResponse)
}
function getBilldata() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    // body: JSON.stringify(EntityRequest)
  };
  // console.log('Service: ', requestOptions, token);
  return fetch(`${baseURL}/get-monite-access-token`, requestOptions).then(
    handleResponse
  );
}
function updateEntity(EntityRequest) {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify(EntityRequest),
  };
  return fetch(`${baseURL}/update-entity `, requestOptions).then(
    handleResponse
  );
}

function getEntities(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-entity/${req.id}`, requestOptions).then(
    handleResponse
  );
}

function getBusinessDetails(req) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
  };
  return fetch(`${baseURL}/get-business-details?${req}`, requestOptions).then(
    handleResponse
  );
}

import React, { useEffect, useState } from 'react';
// import styled from "@emotion/styled";
// import {
//   UQuestionCircle,
//   THEMES,
//   Avatar,
//   Box,
//   Text,
//   Flex,
//   UArrowRight,
// } from "@team-monite/ui-kit-react";
import Box from '@mui/material/Box';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Menu_List from './Menu';
import MenuItem_List from './Menu/MenuItem';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from './styles.module.scss';
import { getUser } from '../../../_helpers';
import { useDispatch, useSelector } from 'react-redux';
import { settingActions } from '../../../_actions/setting.actions';
import { settingSelector } from '../../../_reducers/setting.reducer';
import {
  createMuiTheme,
  ThemeProvider,
  createTheme,
  Button,
  Grid,
  Typography,
  IconButton,
  Alert,
  FormControlLabel,

  CircularProgress,
} from '@mui/material';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Drawer from '@mui/material/Drawer';
import Modal from 'react-bootstrap/Modal';
import MenuIcon from '@mui/icons-material/Menu';
import { useTour } from '@reactour/tour';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { dispatchStore } from '../../../Store/store';
import { userActions } from '../../../_actions';
import { Environment, reloadEnvironmentConfig } from '../../../api/environment';
import { getEnvironment } from '../../../_helpers/environmentProperties';
// import { set } from 'rsuite/esm/utils/dateUtils';
import {AppLoadingScreen} from './AppLoadingScreen';
import { LogoutIcon } from '../Layout/Menu/icon';

type Anchor = 'top' | 'left' | 'bottom' | 'right';



// const Sider = styled(Flex)`
//   position: sticky;
//   top: 0;
//   height: 100vh;

//   flex-direction: column;

//   background: #f6f6f6;

//   a {
//     color: #000;
//     text-decoration: none;
//   }
// `;

type DefaultLayoutProps = {
  children?: React.ReactNode;
};
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 160,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#bbb',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 20,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  //const rootStore = useRootStore();
  const [users, setUsers] = useState<any>();
  const [render, setRender] = useState(true);
  const [userRender, setUserRender] = useState(true);
  const [showModal, setShow] = React.useState(false);
  const userData = useSelector((state) => settingSelector(state));
  const dispatch = useDispatch();
  console.log(users, 'usersusers');
  const { setIsOpen } = useTour();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleShow = () => {
    setShow(true);
    handleClose();
  };
  const onClose = () => {
    setShow(false);
  };
  const environment = getEnvironment();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (getUser && render) {
      setRender(false);
      if (!!getUser()?.data && !!getUser()?.data.user_id) {
        const bodyReq = {
          id: getUser()?.data.user_id,
        };
        dispatch(settingActions.getUserDetail(bodyReq, 'yes') as any);
      }
    }
  }, [render, getUser]);

  React.useEffect(() => {
    if (userRender && userData?.GETUSERDETAIL_SUCCESS !== undefined) {
      setUserRender(false);
      setUsers(userData?.GETUSERDETAIL_SUCCESS);
    }
  }, [userData, userRender]);

  const [state, setState] = React.useState<any>({
    left: false,
  });

  const currentEnvironment = getEnvironment();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const themeline = createTheme({
    palette: {
      primary: { main: '#000' },
      mode: 'light',
    },
  });
  const listtebal: any = localStorage.getItem('newUser');

  // React.useEffect(() => {
  //   if (userData?.GETUSERDETAIL_SUCCESS !== undefined) {
  //     if (JSON.parse(listtebal) == true) {
  //       setIsOpen(true);
  //       localStorage.setItem('newUser', 'false');
  //     } else {
  //       // setIsOpen(true)
  //       toggleDrawer('left', true);
  //       localStorage.setItem('newUser', 'false');
  //     }
  //   }
  //   {
  //     (['left'] as const).map((anchor: any) => toggleDrawer(anchor, true));
  //   }
  // }, [userData, listtebal]);


  const LogoutApi = () => {
    localStorage.removeItem('_expiredTime');
    dispatchStore(userActions.logout('logout', '') as any);
  };

  const [checked, setChecked] = useState(
    getEnvironment() === 'sandbox' ? true : false
  );
  const [environmentLoader, setEnvironmentLoader] = useState(false);
  const [env, setEnv] = useState(Environment.current.name);

  useEffect(() => {
    console.log('******getting here *********');
    const mode = localStorage.getItem('apiMode');
    reloadEnvironmentConfig(mode === 'sandbox' ? 'sandbox' : 'production');
    setEnv(mode ? mode : 'Production');
    // LogoutApi();
  }, [checked]);

  const handleSwitch = (e: any) => {
    setEnvironmentLoader(true);
    setTimeout(() => {
      setEnvironmentLoader(false);
      setChecked(e.target.checked);
    }, 2000);
    localStorage.setItem(
      'apiMode',
      e.target.checked ? 'sandbox' : 'production'
    );
    LOGin(e.target.checked ? 'sandbox' : 'production')
    // LogoutApi();
  };
  const LOGin = (data: any) => {
    var UserEmail = getUser()?.data.email
    console.log(UserEmail, "UserEmailUserEmail");
    var body = {
      email: UserEmail
    }
    dispatchStore(userActions.LoginToken(body, data == "production" ? process.env.REACT_APP_API_URL : process.env.REACT_APP_STAGING_API_URL) as any);
  }
  return (
    <>
      {environmentLoader ? (
        <AppLoadingScreen />
      ) : (
        <>
          <ThemeProvider theme={themeline}>
            <Grid container style={environment == 'sandbox' ? { backgroundColor: "#000" } : { backgroundColor: "#055041" }} className={styles.grid_container}>
              <Grid item md={3} sm={0} xs={0} className={styles.grid_header}>
                <Box className={styles.bsnNamaBox}>
                  <img src="/Group 2505 (1).svg" />
                  <Typography style={{ padding: "0px 0px 0px 7px" }} className={styles.typo_account_name}>
                    {!!users ? !!users.entity ? users.entity.name : '' : ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} sm={0} xs={0} justifyContent={'center'} className={styles.gridtest_header}>
                <Box className={styles.bsnNamaBox}>
                  {environment == 'sandbox' ? <p className={styles.typo_account_name}>
                  Sandbox mode is enabled, real money is NOT being used.
                  </p> : ""}

                </Box>

              </Grid>
              {/* <Grid item md={1} sm={0} xs={0}>

              </Grid> */}
              <Grid item md={4} xs={12} sm={12}>
                <Box className={styles.box_header}>
                  {/* <IconButton>
                <NotificationIcon />
              </IconButton> */}
                  <Box className={styles.account_info_box}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <span className={styles.typo_account_name}>
                        {`${environment == 'sandbox' ? "Sandbox" : 'Production'
                          }`}
                      </span>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            checked={checked}
                            onChange={handleSwitch}
                          />
                        }
                        label={''}
                        labelPlacement="start"
                      />
                    </div>
                  </Box>
                  <Box className={styles.account_info_box}>
                    <div className={styles.account_icon_class}>
                      <AccountIcon />
                    </div>

                    <Typography className={styles.typo_account_name}>
                      {!!users ? (!!users.name ? users.name : '') : ''}
                    </Typography>
                    <IconButton
                      onClick={handleClick}
                      className={styles.info_name}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <Box className={styles.menu_box}>
                <AccountHolderIcon />
                <Box>
                  <Typography className={styles.typo_menuName}>
                    {!!users ? !!users.name ? users.name : '' : ''}
                  </Typography>
                  <Typography className={styles.roleText}>
                    {!!users ? !!users.role_name ? users.role_name : '' : ''}

                  </Typography>
                </Box>
              </Box>
              <Box className={styles.menu_box}>
                <img src="/Group 2509.svg" />
                <Typography className={styles.typo_menuName}>
                  {!!users ? !!users.entity ? users.entity.name : '' : ''}
                </Typography>
              </Box>
              <Box className={styles.menu_box}>
                <img src="/Group 2505 (1).svg" />
                <Typography className={styles.typo_menuName}>
                  {!!users ? !!users.account_number ? users.account_number : '' : ''}
                </Typography>
              </Box>
              <Box className={styles.menu_box}>
                <Typography className={users?.entity?.status == "VERIFIED" ? styles.typo_Active : users?.entity?.status == "UNVERIFIED" || users?.entity?.status == "NEW" ? styles.pending_Typo : ""}>
                  {users?.entity?.status == "VERIFIED" ? "Active" : users?.entity?.status == "UNVERIFIED" || users?.entity?.status == "NEW" ? "Pending verification" : ""}
                </Typography>
              </Box>
              <MenuItem className={styles.logout_menuitem} onClick={() => {

                handleShow()
              }} disableRipple>
                <Box className={styles.menu_box}>
                  <LogoutIcon />{' '}
                  <Typography className={styles.typo_menuName}>
                    Logout
                  </Typography>
                </Box>
              </MenuItem>
            </StyledMenu>
            <Modal
              show={showModal}
              onHide={onClose}
              backdrop="static"
              keyboard={false}
              aria-labelledby='ModalHeader'
              // className='topup-modal'
              centered
            // size="sm"

            ><Modal.Body><Box className={styles.box_dialog_main}>
              <Typography className={styles.typo_text}>Do you wish to proceed to logout?</Typography>
              <Box className={styles.box_btn_cencal}>
                <Button className={styles.cancel_btn} onClick={onClose}>Go back</Button>
                <Button className={styles.delete_btn} onClick={() => {
                  onClose()
                  LogoutApi()
                }}>Yes, logout</Button>
              </Box>
            </Box></Modal.Body></Modal>
            <div className={styles.sEFASFfsaff}>


              <Box sx={{ height: "100%", paddingTop: "50px" }}>
                <Box id={styles.sidebar_textxt} className={styles.sider} >
                  
                  <Box style={{ marginTop: '0px', height: "calc(100vh - 150px)", overflow: "scroll" }}>

                    <Menu_List  setState={setState} setShow={setShow} />
                  </Box>
                  <Box
                    className={styles.add_respons_ahahgagga}
                  >
                    <img src="/assets/sidebar-logo.png" alt="logo" />
                    <p style={{ fontSize: '12px', marginLeft: '8px' }}>Version 2.1.9</p>
                  </Box>
                </Box>
              </Box>
              <>
                {(['left'] as const).map((anchor: any) => (
                  <>
                    {console.log(anchor, 'anchoranchor')}
                    <Button id={styles.add_menu_icon} onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >

                      <Box
                        sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 240 }}
                        role="presentation"
                        onKeyDown={toggleDrawer(anchor, false)}
                      >
                        <Box className={styles.sider}>
                          {/* <div
                        className={styles.sdjffffjfhfh}
                      >
                        <Box>
                          <p className={styles.companyTitle} style={{ fontSize: "18px", fontWeight: "500", marginBottom: "0px" }}>{!!users ? !!users.entity ? users.entity.name : '' : ''}</p>
                          <p className={styles.usernameTitle} style={{ fontSize: "16px", marginBottom: "4px" }} >{!!users ? !!users.name ? users.name : '' : ''}</p>

                          {!!users && !!users.role_name ?
                            <div className='right text-capitalize tag'>
                              <span style={{ fontSize: "14px" }} className={`tag_color tag_payin-tag`}>
                                {!!users ? !!users.role_name ? users.role_name : '' : ''}
                              </span>
                            </div>

                            : ""}
                          <p className={styles.roleTitle}
                            style={{ fontSize: "13px", marginBottom: "0px", paddingTop: "3px" }}>{!!users ? !!users.account_number ? users.account_number : '' : ''}</p>
                          {!!users && !!users.entity.status ? <p className={styles.usernameTitle} style={{ fontSize: "16px", marginBottom: "4px" }} >
                            {users.entity.status == "VERIFIED" ? "Active" : users.entity.status == "UNVERIFIED" || users.entity.status == "NEW" ? "Pending verification" : ""}
                          </p> : ""}
                        </Box>
                        <Box className="usernameTitle">
                        </Box>
                      </div> */}
                          <Box style={{ marginTop: '0px', height: "calc(100vh - 150px)", overflow: "scroll" }}>
                            <Menu_List setState={setState} setShow={setShow} />
                          </Box>
                          <Box className={styles.add_respons_ahahgagga}>
                            <img src="/assets/sidebar-logo.png" alt="logo" />
                            <p style={{ fontSize: '12px', marginLeft: '8px' }}>Version 2.1.9</p>
                          </Box>
                        </Box>
                      </Box>
                    </Drawer>
                  </>
                ))}
              </>

            </div>
          </ThemeProvider>
          <div className={styles.content} >
            <div className={styles.conteant_data_contenar} style={{ marginTop: "54px" }}>
              {children}
            </div></div>
        </>
      )}
    </>

    // </div>
  );
};

export default DefaultLayout;

const NotificationIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33325 15.8337V14.167H4.99992V8.33366C4.99992 7.18088 5.34714 6.15671 6.04159 5.26116C6.73603 4.3656 7.63881 3.77866 8.74992 3.50033V2.91699C8.74992 2.56977 8.87158 2.27477 9.11492 2.03199C9.35825 1.78921 9.65325 1.66755 9.99992 1.66699C10.3471 1.66699 10.6424 1.78866 10.8858 2.03199C11.1291 2.27533 11.2505 2.57033 11.2499 2.91699V3.50033C12.361 3.7781 13.2638 4.36505 13.9583 5.26116C14.6527 6.15727 14.9999 7.18144 14.9999 8.33366V14.167H16.6666V15.8337H3.33325ZM9.99992 18.3337C9.54158 18.3337 9.14936 18.1706 8.82325 17.8445C8.49714 17.5184 8.33381 17.1259 8.33325 16.667H11.6666C11.6666 17.1253 11.5035 17.5178 11.1774 17.8445C10.8513 18.1712 10.4588 18.3342 9.99992 18.3337ZM6.66659 14.167H13.3333V8.33366C13.3333 7.41699 13.0069 6.63227 12.3541 5.97949C11.7013 5.32671 10.9166 5.00033 9.99992 5.00033C9.08325 5.00033 8.29853 5.32671 7.64575 5.97949C6.99297 6.63227 6.66659 7.41699 6.66659 8.33366V14.167Z"
      fill="white"
    />
  </svg>
);
const AccountIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.33366 5.2513C9.33366 5.87014 9.08783 6.46363 8.65024 6.90122C8.21266 7.3388 7.61916 7.58464 7.00033 7.58464C6.38149 7.58464 5.78799 7.3388 5.35041 6.90122C4.91282 6.46363 4.66699 5.87014 4.66699 5.2513C4.66699 4.63246 4.91282 4.03897 5.35041 3.60139C5.78799 3.1638 6.38149 2.91797 7.00033 2.91797C7.61916 2.91797 8.21266 3.1638 8.65024 3.60139C9.08783 4.03897 9.33366 4.63246 9.33366 5.2513ZM8.16699 5.2513C8.16699 5.56072 8.04408 5.85747 7.82528 6.07626C7.60649 6.29505 7.30974 6.41797 7.00033 6.41797C6.69091 6.41797 6.39416 6.29505 6.17537 6.07626C5.95658 5.85747 5.83366 5.56072 5.83366 5.2513C5.83366 4.94188 5.95658 4.64514 6.17537 4.42634C6.39416 4.20755 6.69091 4.08464 7.00033 4.08464C7.30974 4.08464 7.60649 4.20755 7.82528 4.42634C8.04408 4.64514 8.16699 4.94188 8.16699 5.2513Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.00016 0.583984C3.45641 0.583984 0.583496 3.4569 0.583496 7.00065C0.583496 10.5444 3.45641 13.4173 7.00016 13.4173C10.5439 13.4173 13.4168 10.5444 13.4168 7.00065C13.4168 3.4569 10.5439 0.583984 7.00016 0.583984ZM1.75016 7.00065C1.75016 8.21982 2.16608 9.34215 2.86316 10.2335C3.35271 9.59059 3.98427 9.06959 4.7085 8.71117C5.43273 8.35275 6.23001 8.16662 7.03808 8.16732C7.83569 8.16656 8.62295 8.34787 9.33987 8.69744C10.0568 9.04701 10.6845 9.55561 11.1751 10.1845C11.6805 9.52159 12.0208 8.74786 12.1679 7.92733C12.3149 7.1068 12.2644 6.26305 12.0206 5.46589C11.7768 4.66874 11.3467 3.9411 10.7659 3.34317C10.185 2.74525 9.47014 2.29424 8.68039 2.02745C7.89063 1.76066 7.04869 1.68577 6.22425 1.80897C5.3998 1.93217 4.61654 2.24992 3.93928 2.73593C3.26202 3.22194 2.71022 3.86224 2.32955 4.60385C1.94887 5.34545 1.75027 6.16705 1.75016 7.00065ZM7.00016 12.2507C5.79497 12.2525 4.62615 11.8379 3.6915 11.077C4.06771 10.5384 4.56844 10.0987 5.15111 9.79522C5.73377 9.49175 6.38112 9.33352 7.03808 9.33398C7.68684 9.33346 8.32636 9.48773 8.90354 9.78398C9.48071 10.0802 9.97888 10.5099 10.3567 11.0373C9.41475 11.8229 8.22667 12.2524 7.00016 12.2507Z"
      fill="white"
    />
  </svg>
);
const AccountHolderIcon = () => (
  <svg width="32" height="32" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="11" fill="#EFEFEF" />
    <path opacity="0.16" d="M6.3335 14.4993C6.3335 13.8805 6.57933 13.287 7.01691 12.8494C7.4545 12.4118 8.04799 12.166 8.66683 12.166H13.3335C13.9523 12.166 14.5458 12.4118 14.9834 12.8494C15.421 13.287 15.6668 13.8805 15.6668 14.4993C15.6668 14.8088 15.5439 15.1055 15.3251 15.3243C15.1063 15.5431 14.8096 15.666 14.5002 15.666H7.50016C7.19074 15.666 6.894 15.5431 6.6752 15.3243C6.45641 15.1055 6.3335 14.8088 6.3335 14.4993Z" fill="#025041" />
    <path d="M6.3335 14.4993C6.3335 13.8805 6.57933 13.287 7.01691 12.8494C7.4545 12.4118 8.04799 12.166 8.66683 12.166H13.3335C13.9523 12.166 14.5458 12.4118 14.9834 12.8494C15.421 13.287 15.6668 13.8805 15.6668 14.4993C15.6668 14.8088 15.5439 15.1055 15.3251 15.3243C15.1063 15.5431 14.8096 15.666 14.5002 15.666H7.50016C7.19074 15.666 6.894 15.5431 6.6752 15.3243C6.45641 15.1055 6.3335 14.8088 6.3335 14.4993Z" stroke="#025041" stroke-linejoin="round" />
    <path d="M11 9.83301C11.9665 9.83301 12.75 9.04951 12.75 8.08301C12.75 7.11651 11.9665 6.33301 11 6.33301C10.0335 6.33301 9.25 7.11651 9.25 8.08301C9.25 9.04951 10.0335 9.83301 11 9.83301Z" stroke="#025041" />
  </svg>

)